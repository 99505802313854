@tailwind base;
@tailwind components;
@tailwind utilities;

/* Firefox */
@media (prefers-color-scheme: dark) {
    :root {
        color-scheme: only light;
    }
}

/* Chrome */
@media (forced-colors: active) {
    :root {
        color-scheme: only light;
    }
}

:root {
    color-scheme: only light;
}
